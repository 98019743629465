import { Link } from 'react-router-dom';
import '../Static/App.css';
import logo from '../Static/logo-128.png'
function Footer() {
    return (
        <div className="container">
        <div className="sponser">
          <img className="powered" src={logo} alt='...' />
          <Link to="https://www.fdalytics.com/">
          <div className="powered-text">
            Powered by Fdalytics
          </div>
          </Link>
        </div>
        </div>
    );
  }

export default Footer;