import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function Success() {
    return (
        <div className="landing-page">
            <Navbar />
          <div className="success-container">
          <div className="success-message active">
        <svg viewBox="0 0 76 76" className="success-message__icon icon-checkmark">
            <circle cx="38" cy="38" r="36"/>
            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7"/>
        </svg>
        <h1 className="success-message__title">Registeration Successful</h1>
        <div className="success-message__content">
            <p>You will start receiving notifications soon.</p>
            <br/>
            <Link className='success-button' to='https://www.fdalytics.com/'>Explore Fdalytics</Link>
        </div>
    </div>
        </div>
            <Footer />
        </div>
    );
  }

export default Success;