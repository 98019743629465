import { Link } from 'react-router-dom';
import '../Static/App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import bgvideo from '../Static/bgvideo.mp4'
function LandingPage() {
    return (
      <div className="landing-page">
        <Navbar />
        <div className="content">
          <div className="container">
            <div className="info">
              <h1>Spam Free Notification</h1>
              <p>
              Get exclusive, spam-free US-FDA related notifications delivered directly to your WhatsApp. Rest assured, we prioritize your privacy and do not share your data. Stay updated with the latest news that matter to you.
              </p>
              <Link to="/signup">
              <button>
                
                  Sign up for latest notifications
                
              </button>
              </Link>
            </div>
              <div className='image'>
              <video width="240" src={bgvideo} autoPlay muted>
                <source src={bgvideo} type="video/mp4"></source>
              </video>
              </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

export default LandingPage;