import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import '../Static/Signup.css';
import countries from '../Static/countryData';
import whatsappIcon from '../Static/whatsapp_icon.svg'

function SignupPage() {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    country_code: '+91',
    phone_number: '',
    email: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCountryChange = (e) =>{
    setSelectedCountry(e.target.key)
    setFormData({ ...formData, 'country_code': e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    setLoading(true);

    // Check for empty fields
    for (const key in formData) {
      if (formData[key] === '' && key !== 'country_code') {
        formIsValid = false;
        break;
      }
    }

    if (formIsValid) {
      try {
        await axios.post('https://codecarbonhq-regiwiz-backend.azurewebsites.net/api/signup/', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        window.location.href = '/success';
      } catch (error) {
        setNotification('Error submitting the form. Please try again.');
        console.error('Error signing up:', error.response.data);
      }
    } else {
      setNotification('Please fill in all required fields.');
      const inputs = document.querySelectorAll('.form-container input');
      inputs.forEach((input) => {
        if (input.value === '') {
          input.style.border = '1px solid red';
        } else {
          input.style.border = '1px solid #ccc';
        }
      });
    }
    setLoading(false);
  };

  // Sort the countries before putting in option tags
  const sortedCountries = [...countries].sort((a, b) => {
    const countryNameA = a.name.common.toUpperCase();
    const countryNameB = b.name.common.toUpperCase();
  
    if (countryNameA < countryNameB) {
      return -1;
    }
    return 1;
  });

  const countryOptions = sortedCountries.map((country) => {
    const suffix = country.idd.suffixes.length <= 1 ? country.idd.suffixes[0] : '';
    return (
      <option key={country.cca2} value={`${country.idd.root}${suffix}`}>
        {country.name.common} ({country.idd.root}{suffix})
      </option>
    );
  });

  return (
    <>
      <div className="landing-page">
        <Navbar />
      </div>
      <div className="content">
        <div className="container">
          <form onSubmit={handleSubmit} className="form-container">
          {notification && <div className='notification'>{notification}</div>}
            <input
              type="text"
              name="name"
              placeholder="Name *"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="company"
              placeholder="Company *"
              value={formData.company}
              onChange={handleInputChange}
            />
            <div style={{ position: 'relative', width: '100%' }}>
              <select
                name="country_code"
                value={selectedCountry}
                onChange={handleCountryChange}
                style={{ width: '39%', marginRight: '1%' }}
              >
                <option value="+91">India (+91)</option>
                {countryOptions}
              </select>
              <div style={{ position: 'relative', width: '60%', display: 'inline-block' }}>
                <input
                  type="text"
                  name="phone_number"
                  placeholder="Whatsapp Phone Number *"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  style={{ width: '100%' }}
                />
                <img
                  src={whatsappIcon}
                  alt="WhatsApp Icon"
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '50%',
                    transform: 'translateY(-80%)',
                    width: '25px',
                  }}
                />
              </div>
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email ID *"
              value={formData.email}
              onChange={handleInputChange}
            />
            <button type="submit" disabled={isLoading}>
              Sign Up
            </button>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default SignupPage;
