import { Link, useLocation } from 'react-router-dom';
import '../Static/App.css';
function Navbar() {
    const location = useLocation();
    return (
        <header>
          <div className="container">
            <Link to="/" className="logo">FDA <b>Notification</b></Link>
            <ul className="links">
            {location.pathname !== '/signup' && (
              <Link to="/signup">
            <li>
              Get Started
            </li>
            </Link>
          )}
            {location.pathname === '/signup' && (
              <Link to="/">
            <li>
              Home
            </li>
            </Link>
          )}
            </ul>
          </div>
        </header>
    );
  }

export default Navbar;